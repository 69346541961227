import React from 'react';
import { connect } from 'react-redux';
import './App.css';


class Notfound extends React.Component {
    constructor(props) {
        super(props);
        this.redirectToASPSP = this.redirectToASPSP.bind(this);
    }
    
    redirectToASPSP()
    {
        this.props.dispatch({type: 'SHOW_BACKDROP', payload:true});
        this.props.dispatch({type: 'SUBMIT_PAYMENT'})
    }

    render()
    {
        return (
            <div style={{padding:'20px'}}>
            <h1>Authorization Required</h1>
            <p>This server could not verify that you
                are authorized to access the document
                requested.  Either you supplied the wrong
                credentials (e.g., bad password), or your
                browser doesn't understand how to supply
                the credentials required.</p>
                </div>
        )
    };
}

const mapStateToProps = (state) => {
    return {...state.transfer, ...state.showBackDrop, ...state.message};
}

export default (connect(mapStateToProps)(Notfound));
