import React from 'react';
import { connect } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DeleteIcon from '@material-ui/icons/Delete';
import Backdrop from '@material-ui/core/Backdrop';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from "react-router-dom";
import './App.css';


import $ from 'jquery';
const useStyles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});
class Transfer extends React.Component {
    constructor(props) { 
        super(props);
        this.submit = this.submit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangLabel = this.handleChangLabel.bind(this);
        this.stepDownDecrease=this.stepDownDecrease.bind(this);
        this.stepDownAdd=this.stepDownAdd.bind(this);
        this.valueChanged=this.valueChanged.bind(this);
        this.valueChangeOne=this.valueChangeOne.bind(this);
        this.valueChangeTwo=this.valueChangeTwo.bind(this);
        this.closeModel=this.closeModel.bind(this);
        
        this.state={
            value: 1,
            product1:100.50,
            product2:100.50,
            productsQuantity1:0,
            productsQuantity2:0,
            price1:100.50,
            price2:100.50,
            price:201.00,
            checked:true,
            valueLabel:'hangseng bank',
        }
        this.redirectToASPSP = this.redirectToASPSP.bind(this);
    }
    redirectToASPSP()
    {
        this.props.dispatch({type: 'SHOW_BACKDROP', payload:true});
        this.props.dispatch({type: 'SUBMIT_PAYMENT'})
    }
      updateBasicExampleTotal = () => {
        if(Number($('#basicOne').val())===1 && Number($('#basicTwo').val())===1){
        this.setState.price = (this.state.product1 + this.state.product2).toFixed(2);
        }else if(Number($('#basicOne').val())===1 && Number($('#basicTwo').val())!==1){
        this.setState.price = (this.state.product1 + this.setState.product2).toFixed(2);
        }else if(Number($('#basicTwo').val())===1 && Number($('#basicOne').val())!==1){
        this.setState.price = (this.setState.product1 + this.state.product2).toFixed(2);
        }else{
        this.setState.price = (this.setState.product1 + this.setState.product2).toFixed(2);
        }
        $('#basic-example-price').html(this.setState.price);
        $('#basic-example-total').html(this.setState.price);
        this.props.transfer_data.Data.Initiation.InstructedAmount.Amount=this.setState.price;
        
      }
    valueChangeOne(e){
        this.setState.productsQuantity1 = $(e.currentTarget).siblings('input').val()
        this.setState.product1 = this.setState.productsQuantity1 * this.state.price1;
        this.updateBasicExampleTotal();
    }
    valueChangeTwo(e){
        this.setState.productsQuantity2 = $(e.currentTarget).siblings('input').val()
        this.setState.product2 = this.setState.productsQuantity2 * this.state.price2;
        this.updateBasicExampleTotal();
    }
    submit(e)
    {
        this.props.dispatch({type: 'TRANSFER_SUBMIT', payload: this.state});
    }

    handleChange = (e) => {
        this.props.dispatch({type: 'check_mobile', payload: e.target.checked});
    }

    stepDownDecrease(e){
        this.setState({
            value:(e.target.parentNode.querySelector('input[type=number]')).stepDown()
        })
    }
    stepDownAdd(e){
        this.setState({
            value:(e.target.parentNode.querySelector('input[type=number]')).stepUp()
        })
    }
    valueChanged(e){
        this.setState({
            value:e.target.value
        })
    }
    handleChangLabel(e){
       this.setState({
         valueLabel:e.target.value
       });
       if(e.target.value!=='hangseng bank'){
         $('#btn-payee').attr('disabled','true');
       }else{
        $('#btn-payee').removeAttr('disabled');
       }
    }
    closeModel(){
      if(btoa($('#userName').val()+':'+$('#passWord').val())==='YW1zdXNlcjpQcjB0ZWN0YW1zdXNlcg=='){
        $('.App-header').css('display','block');
        $('#model').css('display','none');
        $('.skin-light').css('display','block');
      }else{
        $('#userName').val('');
        $('#passWord').val('');
        $('#model').css('display','block');
        $('.skin-light').css('display','none');
      }
    }
    center=(obj)=> {
 
      var screenWidth = $(window).width();   
      var objLeft = (screenWidth - obj.width())/2 ;
      if(screenWidth<='500'){
        objLeft=0;
      }
      obj.css({left: objLeft + 'px'});

      $(window).resize(()=> {
          screenWidth = $(window).width();
          objLeft = (screenWidth - obj.width())/2 ;
          obj.css({left: objLeft + 'px'});
    
      });

      $(window).scroll(()=> {
          screenWidth = $(window).width();
          objLeft = (screenWidth - obj.width())/2 ;
          obj.css({left: objLeft + 'px'});
      });
    
    }
    componentDidMount() { 
      $('.App-header').css('display','none');
      this.center($('#model'));
    }
    render()
    {
    
        const { classes } = this.props; 
        const isDefault=this.setState.price===undefined;
        if(isDefault){
          sessionStorage.setItem('price',this.state.price);
        }else{
          sessionStorage.setItem('price',this.setState.price);
        }
        if(window.location.href.indexOf('ac')>=0){
          this.props.transfer_data.Data.Initiation.CreditorAccount.Identification=window.location.search.slice(4)
        }
        
        return ( 
            <div>  
              <div  >
              <div className="model" id="model" style={{position:'absolute',top:'0',left:'0',padding:'15px',fontSize:'14px',border:'1px solid #88888836',boxShadow:'8px 4px 10px #8888889c',borderRadius:'4px'}}>
               <p style={{textAlign:'left'}}>Sign in</p>
               <div style={{marginBottom:'20px'}}>
                 <span style={{marginRight:'15px'}}>Username</span>
                 <input id="userName" type="text" style={{display:'inline-block',width:'300px'}}/>
               </div>
               <div>
                 <span style={{marginRight:'18px'}}>Password</span>
                 <input id="passWord" type="password" style={{display:'inline-block',width:'300px'}}/>
               </div>
               <div style={{marginTop:'20px',textAlign:'right'}}>
                 <button type="submit" className="btn-primary" style={{backgroundColor:'#007bff',color:'#fff',border:'none',borderRadius:'4px',padding:'5px 10px',marginRight:'6px'}} onClick={this.closeModel}>Sign in</button>
                 <Link to='/notfound'>
                 <button type="submit" style={{backgroundColor:'#fff',color:'#007bff',border:'1px solid #d9d9d9',borderRadius:'4px',padding:'5px 10px'}}>Cancel</button>
                 </Link>
               </div>
              </div>
              </div>
            <div className="skin-light" style={{display:'none'}}>

<div className="row">

  <div className="col-lg-8">

    <div className="mb-3">
      <div className="pt-4 wish-list">

        <h5 className="mb-4">Shopping Cart</h5>

        <div className="row mb-4">
          <div className="col-md-5 col-lg-3 col-xl-3">
            <div className="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
              <img className="img-fluid w-100"
                src="12a.jpg" alt="Sample"/>
              <a href="#!">
                <div className="mask">
                  <img className="img-fluid w-100"
                    src="12.jpg" alt="blue shirt"/>
                  <div className="mask rgba-black-slight"></div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-7 col-lg-9 col-xl-9">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>Blue denim shirt</h5>
                  <p className="mb-3 text-muted text-uppercase small">Shirt - blue</p>
                  <p className="mb-2 text-muted text-uppercase small">Color: blue</p>
                  <p className="mb-3 text-muted text-uppercase small">Size: M</p>
                </div>
                <div>
                  <div className="def-number-input number-input safari_only mb-0 w-100">
                    <button id="basic-example-decrease" onClick={(e)=>{this.stepDownDecrease(e);this.valueChangeOne(e)}}
                      className="minus decrease"></button>
                    <input id="basicOne" className="quantity" min="0" name="quantity" defaultValue={this.state.value} type="number" onChange={this.valueChanged}/>
                    <button id="basic-example-add" onClick={(e)=>{this.stepDownAdd(e);this.valueChangeOne(e)}}
                      className="plus increase"></button>
                  </div>
                  <small id="passwordHelpBlock" className="form-text text-muted text-center">
                    (Note, 1 piece)
                  </small>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="card-link-secondary small text-uppercase"><DeleteIcon/> Remove item </span>
                  <span href="#!" type="button" className="card-link-secondary small text-uppercase"><FavoriteIcon/> Move to wish list </span>
                </div>
        <p className="mb-0"><span><strong id="summary">${this.state.price1}</strong></span></p>
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-4"/>
        <div className="row mb-4">
          <div className="col-md-5 col-lg-3 col-xl-3">
            <div className="view zoom overlay z-depth-1 rounded mb-3 mb-md-0">
              <img className="img-fluid w-100"
                src="13a.jpg" alt="Sample"/>
              <a href="#!">
                <div className="mask">
                  <img className="img-fluid w-100"
                    src="13.jpg" alt="red shirt"/>
                  <div className="mask rgba-black-slight"></div>
                </div>
              </a>
            </div>
          </div>
          <div className="col-md-7 col-lg-9 col-xl-9">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <h5>Red hoodie</h5>
                  <p className="mb-3 text-muted text-uppercase small">Shirt - red</p>
                  <p className="mb-2 text-muted text-uppercase small">Color: red</p>
                  <p className="mb-3 text-muted text-uppercase small">Size: M</p>
                </div>
                <div>
                  <div className="def-number-input number-input safari_only mb-0 w-100">
                  <button id="basic-example-decrease" onClick={(e)=>{this.stepDownDecrease(e);this.valueChangeTwo(e)}}
                      className="minus decrease"></button>
                    <input id="basicTwo" className="quantity" min="0" name="quantity" defaultValue={this.state.value} type="number" onChange={this.valueChanged}/>
                    <button id="basic-example-add" onClick={(e)=>{this.stepDownAdd(e);this.valueChangeTwo(e)}}
                      className="plus increase"></button>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="card-link-secondary small text-uppercase"><DeleteIcon/> Remove item </span>
                  <span  className="card-link-secondary small text-uppercase"><FavoriteIcon/> Move to wish list </span>
                </div>
                <p className="mb-0"><span><strong>${this.state.price2}</strong></span></p>
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>

  </div>
  <div className="col-lg-4">
    <div className="mb-3">
      <div className="pt-4">

        <h5 className="mb-3">The total amount of</h5>

        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
            <div>
              <strong>The total amount of</strong>
              <strong>
                <p className="mb-0">(including VAT)</p>
              </strong>
            </div>
            <span id="basic-example-total"><strong>${this.state.price}</strong></span>
          </li>
        </ul>
        
        <Backdrop className={classes.backdrop} open={this.props.showBackDrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                     <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Payee information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <table>
                            <tbody>
                              {
                                window.location.href.indexOf('ac')>=0?
                                  <tr>
                                  <td>Account number:</td>
                                  <td className="information-text"><input type="text" onChange={e => this.setState(prevState => {
                                        let data = Object.assign({}, this.props);
                                        data.transfer_data.Data.Initiation.CreditorAccount.Identification = e.target.value;
                                        return {data};
                                    })}  value={window.location.search.slice(4)} /></td>
                                </tr>:<tr>
                                    <td>Account number:</td>
                                    <td className="information-text"><input type="text" onChange={e => this.setState(prevState => {
                                        let data = Object.assign({}, this.props);
                                        data.transfer_data.Data.Initiation.CreditorAccount.Identification = e.target.value;
                                        return {data};
                                    })} value={this.props.transfer_data.Data.Initiation.CreditorAccount.Identification} /></td>
                                </tr>
                              }
                                
                                <tr>
                                    <td>Remark:</td>
                                    <td className="information-text"><input type="text" onChange={e => this.setState(prevState => {                                                                             
                                                                let data = Object.assign({}, this.props);
                                                                data.transfer_data.Data.Initiation.RemittanceInformation.Reference = e.target.value;
                                                                return {data};      
                                                            })} value={this.props.transfer_data.Data.Initiation.RemittanceInformation.Reference} /></td>
                                </tr>
                                <tr>
                                    <td>Total amount:</td>
                                    <td className="information-text"><input type="text" onChange={e => this.setState(prevState => {                                                                             
                                                                let data = Object.assign({}, this.props);
                                                                data.transfer_data.Data.Initiation.InstructedAmount.Amount = e.target.value;
                                                                return {data};      
                                                            })} value={this.props.transfer_data.Data.Initiation.InstructedAmount.Amount} /></td>
                                </tr>
                            </tbody>
                        </table>
          </Typography>
        </AccordionDetails>
      </Accordion>
        <div className="payment-tips">
        <FormGroup row>
                        <FormControlLabel
                            labelPlacement="start" control={<Checkbox checked={this.props.checkMobile} onChange={this.handleChange} name="checkMobile" />}
                            label="Use discount code or not?"
                        />
                    </FormGroup>
                    </div>
                    <FormControl component="fieldset">
  <FormLabel component="legend">Payment method</FormLabel>
  <RadioGroup aria-label="gender" name="gender1" value={this.state.valueLabel} onChange={this.handleChangLabel}>
    <FormControlLabel value="hangseng bank" control={<Radio />} label={<img  width="40px"
            src="1.png"
            alt="hangsengbank"></img>}/>
    <FormControlLabel value="male" control={<Radio />} label={<img  width="40px"
            src="mastercard.svg"
            alt="Mastercard"></img>} />
            <img style={{marginBottom:5+'px'}} width="40px"
            src="visa.svg"
            alt="Visa"/>
    <FormControlLabel value="other" control={<Radio />} label={<img  width="40px"
            src="amex.svg"
            alt="American Express"></img>} />
    <FormControlLabel value="others"  control={<Radio />} label={<img  width="40px"
            src="paypal.png"
        alt="PayPal acceptance mark"/>}/>
  </RadioGroup>
</FormControl>
                    
<form action="https://uat-fintech.hangseng.com/payment" method="post">
        <button id="btn-payee" type="submit" className="btn btn-primary btn-pay" onClick={()=> this.props.dispatch({type: 'SHOW_BACKDROP', payload:true})}>PAY</button> 
        <input type="hidden" id="transfer_data" name="transfer_data" value={JSON.stringify(this.props.transfer_data)} />
                <input type="hidden" id="checkMobile" name="checkMobile" value={JSON.stringify(this.props.checkMobile)} />                 
        </form>
      </div>
      
    </div>

  </div>

</div>
</div>            
            </div>
            
              )
            };
}
const mapStateToProps = (state) => {
    return {...state.transfer,...state.showBackDrop, checkMobile:state.checkMobile};
}

export default withStyles(useStyles) (connect(mapStateToProps)(Transfer));
