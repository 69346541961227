import { combineReducers } from 'redux'
const moment = require('moment');

const initialState = {
   transfer_data:{
      Data: {
         Initiation: {
            InstructionIdentification: "TYVZv4cN60Cmn1xEE64W",
            EndToEndIdentification: "U55YIeZJKvaCPANDzSXV",
            InstructedAmount: {
                Amount: "201.00",
                Currency: "HKD"
            },
            CreditorAccount: {
                SchemeName: "UK.HSBC.LocalAccountNumber",
                Identification: "200011203883",
                Name: "2A888HKD       HKDSASAV",
                SecondaryIdentification: "024"
            },
            RemittanceInformation: {
                Unstructured: "Ll0Mau83VNvkbHlX74",
                Reference: "NUp2s" + new Date().getTime()
            },
            SupplementaryData: {
                PurposeOfPayment: "Buying Goods"
            }
        },
        Authorisation: {
            AuthorisationType: "Any",
            CompletionDateTime: moment().add(1, 'day').format("YYYY-MM-DD") + "T12:45:59+00:00"
        }
      },
      Risk: {
   
      }
   },
   checkMobile: false,
   showBackDrop: false,
   showError: false
}

const transferReducer = (state = initialState, action) => {
    switch (action.type) {
        case "check_mobile":
            return {...state, checkMobile:action.payload};
        case "SHOW_BACKDROP":
            return {...state, showBackDrop:action.payload, showError:false};
        case "SUBMIT_PAYMENT_SUCCEEDED":
            return {data: action.payload};
        case "SUBMIT_PAYMENT_FAILED":
            return {error:action.payload};
        case "TRANSFER_SUBMIT":
            let data = Object.assign({}, state);
            let amount = state.transfer_data.Data.Initiation.InstructedAmount.Amount;
            data.transfer_data.Data.Initiation.InstructedAmount.Amount = Number(amount).toFixed(Math.max(2, (amount.toString().split('.')[1] || []).length));
            return {...state, ...data}
        default:
            return state;
     }
  };

export default combineReducers({
   transfer: transferReducer
})
