import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css';
import Transfer from './Transfer.js';
import Confirm from './Confirm.js';
import Acknowledgement from './Acknowledgement.js';
import Header from './Header.js';
import Notfound from './Notfound.js';
import url from "url";
import querystring from "querystring";

export default function App() {
  return (
    <Router>
      <Header />
      <div>
        {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
        <Switch>
          <Route exact path="/" render={()=> {
            let hashValue = url.parse(window.location.href).hash;
            let fragmentParams;
            if(hashValue)
              fragmentParams = querystring.parse(hashValue.replace('#', ''));
            if(fragmentParams && fragmentParams.code)
              return <Acknowledgement />;
            else
              return <Transfer />;
          }} />
          <Route path="/confirm">
            <Confirm />
          </Route>
          <Route path="/ack">
            <Acknowledgement />
          </Route>
          <Route path="/notfound">
            <Notfound />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
