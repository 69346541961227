import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import url from "url";
import querystring from "querystring";

const moment = require('moment');

class Acknowledgement extends React.Component {
    constructor(props) {
        super(props);
        this.submitPayment = this.submitPayment.bind(this);
        this.handleHome=this.handleHome.bind(this);

        this.submitPayment();
    }

    componentDidMount() { // 组件挂载完毕调用 只调用一次

    }
    componentWillUnmount(){ // 组件将要卸载

    }

    submitPayment()
    {
        let fragmentParams = querystring.parse(url.parse(window.location.href).hash.replace('#', ''));
        this.props.dispatch({type: 'PAYMENT_FULFILMENT', payload: fragmentParams});
    }
    handleHome(){
        const oa=document.createElement('a');
        oa.href="https://uat-fintech.hangseng.com";
        oa.setAttribute('target','_self');
        document.body.appendChild(oa);
        oa.click();
    }

    render()
    {
        if (!this.props.data && !this.props.error){
            return <div style={{textAlign:"center", marginTop: "50px"}}><CircularProgress/></div>;
        }
        if(this.props.error)
        {
          
            return <div>{this.props.error}</div>
        }
        return (
            <div className="ack-content">
            <div >
                <h2 style={{textAlign:'center'}}>Completed!</h2>
                <div style={{textAlign:'center'}} >
                <img width="100px"
            src="right.jpg"
            alt="check"></img>
            </div>
                <div>
                    <p>Your payment is confirmed and we will arrange delivery shortly.</p>
                </div>
                <div>
                    <p className="pay-details">Payment details</p>
                    <table className="payee_info payee-amount">
                        <tbody>
                        <tr>
                            <td>Order reference:</td>
                            <td>{this.props.data.Data.Initiation.RemittanceInformation.Reference}</td>
                        </tr>
                        <tr>
                            <td>Total paid:</td>
                            <td>{this.props.data.Data.Initiation.InstructedAmount.Currency} 
                            {this.props.data.Data.Initiation.InstructedAmount.Amount}</td>
                        </tr>
                        <tr>
                            <td>Payment Method:</td>
                            <td>Hang Seng Mobile App</td>
                        </tr>
                        <tr>
                            <td>Status:</td>
                            <td>{this.props.data.Data.Status}</td>
                        </tr>
                        <tr>
                            <td>Time:</td>
                            <td>{moment(this.props.data.Data.StatusUpdateDateTime).utcOffset('+0800').format('YYYY-MM-DDTHH:mm:ss+08:00')}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <button  className="btn btn-finish"style={{marginTop: 2 + 'em'}} onClick={()=>{this.handleHome()}}>Finish</button>
                </div>
            </div>
            </div>
        )
    };
}

const mapStateToProps = (state) => {
    return {...state.transfer};
}

export default connect(mapStateToProps)(Acknowledgement);
