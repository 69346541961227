import './App.css';

function Header() {
    return (
        <div className="App">
            <header className="App-header">
                <p className="App-header-text">
                ABC Merchant Ltd
                </p>
            </header>
        </div>
    );
}

export default Header;